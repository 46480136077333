import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { linking } from "./src/navigation/routes";
import RootAppController from "./src/root/controllers/RootAppController";
import Toast from "react-native-toast-message";
import Logger from "./src/common/utils/Logger";
import {
  asyncStorageGetData,
  asyncStorageSetData,
} from "./src/common/utils/ControllerUtils";
import { UserProvider } from "./src/context/UserContext";
import RootAuthController from "./src/root/controllers/RootAuthController";
import client, { parseAPIUrlFromLocation } from "./src/api/client";
import locationsdata from "./src/root/data/locationsdata";

export const navigationRef = React.createRef();

export default function App() {
  const [user, setUser] = useState();
  const [isReady, setIsReady] = useState(false);
  const DEFAULT_USER_STORE_LOCATION = "@nthaadmin_user_location";

  const updateLocation = (location) => {
    if (location) {
      client.setBaseURL(parseAPIUrlFromLocation(location));
      Logger("Will update the API base URL to", client.getBaseURL());
    }
  };

  const loadUser = async () => {
    var user = await asyncStorageGetData(DEFAULT_USER_STORE_LOCATION);

    user = JSON.parse(user);

    //Will never initialize the user to null,
    if (!user || Object.keys(user).length == 0) {
      user = {};
    }

    updateLocation(user?.location);
    setUser(user);
    setIsReady(true);
    Logger("Current user loaded: ", user);
  };

  const updateUser = (user) => {
    asyncStorageSetData(DEFAULT_USER_STORE_LOCATION, user);
    setUser(user);
    updateLocation(user?.location);
  };

  if (!isReady) {
    //will fix this in a bit....
    loadUser();
    return null;
  }

  return (
    <View style={styles.container}>
      <UserProvider
        value={{
          user,
          updateUser,
          updateLocation,
        }}
      >
        <NavigationContainer linking={linking} ref={navigationRef}>
          {user?.authenticated ? <RootAppController /> : <RootAuthController />}
          <Toast />
        </NavigationContainer>
      </UserProvider>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
