import { create } from "apisauce";
import settings from "../config/settings";
import Logger from "../common/utils/Logger";

export const parseAPIUrlFromLocation = (location) => {
  if (location?.tls) {
    return `https://${location?.domain}/api/v1`;
  }
  return `http://${location?.domain}:31184/api/v1`;
};

process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";
const client = create({
  baseURL: settings.apiUrl,
  headers: { Accept: "application/json", "Content-Type": "application/json" },
});

client.addAsyncRequestTransform(async (request) => {
  request.headers["Content-Type"] = "application/json";
  request.headers["Accept"] = "application/json";
  request.headers["Authorization"] = `Basic ${settings.apiAuth}`;
});

const post = client.post;
client.post = async (url, params, axiosConfig) => {
  const response = await post(url, params, axiosConfig);

  if (response.status === 204) {
    Logger(
      "Just got a 204 from API will handle differently to avoid breaking promise"
    );
    return { ok: true, data: [] };
  }

  return response;
};

export default client;
